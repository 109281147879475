/* This example requires Tailwind CSS v2.0+ */
import { AnnotationIcon, ShieldCheckIcon, LightningBoltIcon, UserIcon, ScaleIcon, AcademicCapIcon, HandIcon, LightBulbIcon, StarIcon, ShareIcon} from '@heroicons/react/outline'
import objective from '../assets/bwomen.jpg';
import value from '../assets/value.jpg';

const objectives = [
  {
    id: 1,
    name: 'PROTECT WOMEN',
    description:
      'Advocate for the protection of women against Sexual and Gender Based Violence and any form of exploitation.',
    icon: ShieldCheckIcon,
  },
  {
    id: 2,
    name: 'EDUCATE',
    description:
      'Promote equal access to education for all women and the girl child..',
    icon: AcademicCapIcon,
  },
  {
    id: 3,
    name: 'EMPOWER WOMEN',
    description:
      'Promote social and economic empowerment of women. Socially by promoting access to clean water, quality and affordable health care. Economically by promoting access to finance and financial literacy.',
    icon: HandIcon,
  },
  {
    id: 4,
    name: 'PROMOTE WOMEN',
    description:
      'Advocate for increased roles for women in decision making positions i.e. in politics, civil service and corporate governance.',
    icon: LightningBoltIcon,
  },
]
const values = [
  {
    id: 1,
    name: 'EQUALITY AND EQUITY',

    icon: AnnotationIcon,
  },
  {
    id: 2,
    name: 'INTEGRITY',
 
    icon: ScaleIcon,
  },
  {
    id: 2,
    name: 'INNOVATION',
 
    icon: LightBulbIcon,
  },
  {
    id: 3,
    name: 'PARTNERSHIP',
 
    icon: ShareIcon,
  },
  {
    id: 4,
    name: 'EMPOWERMENT',
 
    icon: StarIcon,
  },
  {
    id: 5,
    name: 'ACCOUNTABILITY',
 
    icon: UserIcon,
  },
]

export default function ValuesObjectives() {
  return (
    <div className="py-16 bg-gray-50 overflow-hidden lg:py-24">
      <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
        <svg
          className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
          width={404}
          height={784}
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width={404} height={784} fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)" />
        </svg>

        <div className="relative">
          <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            VISION
          </h2>
          <p className="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500">
          African continent where women are protected, economically and socially empowered, promoted in decision making positions and educated.
          </p>
        </div>
        <div className="relative mt-4">
          <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
           MISSION
          </h2>
          <p className="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500">
          To unite women in Africa and create an atmosphere where women come together to share their experiences, harness their skills, synergize resources/ influence and bring about lasting impact on women empowerment.
          </p>
        </div>
        <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div className="relative">
            <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
              OBJECTIVES
            </h3>
            <p className="mt-3 text-lg text-gray-500">
            Through empowerment and hard work, strong women in Africa have been successful in the social, economic and political circles across the continent.
            </p>

            <dl className="mt-10 space-y-10">
              {objectives.map((item) => (
                <div key={item.id} className="relative">
                  <dt>
                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-green-500 text-white">
                      <item.icon className="h-6 w-6" aria-hidden="true" />
                    </div>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{item.name}</p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">{item.description}</dd>
                </div>
              ))}
            </dl>
          </div>

          <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
            <svg
              className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
              width={784}
              height={404}
              fill="none"
              viewBox="0 0 784 404"
            >
              <defs>
                <pattern
                  id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={784} height={404} fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)" />
            </svg>
            <img
              className="relative mx-auto sm:rounded-2xl"
              width={490}
              src={objective}
              alt=""
            />
          </div>
        </div>

        <svg
          className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"
          width={404}
          height={784}
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width={404} height={784} fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
        </svg>

        <div className="relative mt-12 sm:mt-16 lg:mt-24">
          <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div className="lg:col-start-2">
              <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
                VALUES
              </h3>
              <p className="mt-3 text-lg text-gray-500">
        
              </p>

              <dl className="mt-10 space-y-10">
                {values.map((item) => (
                  <div key={item.id} className="relative">
                    <dt>
                      <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-green-500 text-white">
                        <item.icon className="h-6 w-6" aria-hidden="true" />
                      </div>
                      <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{item.name}</p>
                    </dt>
          
                  </div>
                ))}
              </dl>
            </div>

            <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
              <svg
                className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                width={784}
                height={404}
                fill="none"
                viewBox="0 0 784 404"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="e80155a9-dfde-425a-b5ea-1f6fadd20131"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={784} height={404} fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)" />
              </svg>
              <img
                className="relative mx-auto sm:rounded-2xl"
                width={490}
                src={value}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}