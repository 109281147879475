import './App.css';
import Footer from './components/Footer';
import Header from './components/Header';
import Contact from './Contact/Contact';
import Herosection from './herosection/Herosection';
import LandingPage from './LandingPage';
import MissionVision from './MissionVision/MissionVision';
import ValuesObjectives from './ObjectivesValues/ObjectivesValues';
import Programmes from './Programmes/Programmes';

function App() {
  return (
      <>
      <Header/>  
       <main>
      <Herosection/>
   
      <ValuesObjectives/>
      </main>
      <Programmes/>
      <Contact/>
      <Footer/>
      </>
  );
}

export default App;
