import React from 'react'
import mamadrive from '../assets/mamadrive.png'
import greenmama from '../assets/green.png'

const Programmes = () => {
  return (
    <div className="relative pt-16 pb-32 overflow-hidden">
    <div aria-hidden="true" className="absolute inset-x-0 top-0 h-48 bg-gradient-to-b from-gray-100" />
    
    <div className="relative">
        <h3 className="text-2xl text-center pb-10 font-extrabold text-gray-900 tracking-tight sm:text-3xl">
        OUR PROGRAMMES
            </h3> 
      <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
     
        <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
          <div>
        
            
            <div className="mt-6">
              <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
              1.	MAMA DRIVE – TAXI HAILING, LOGISTICS/ DELIVERY, TRANSPORT BOOKING APPLICATION
              </h2>
              <p className="mt-4 text-lg text-gray-500">
              Mama Drive is an app-powered, on-demand transport service provider for smart phones. The Company will charge a 8% commission for connecting customers with taxi drivers via a cutting-edge mobile application.
<br/>
Under this project women will be offered a credit facility through our banking partner to buy vehicles which will in turn be used on our transport hailing platform for business with flexible repayment terms therefore economically empowering women.

              </p>
              {/* <div className="mt-6">
                <a
                  href="/"
                  className="inline-flex bg-gradient-to-r from-green-600 to-green-600 bg-origin-border px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-green-700 hover:to-green-700"
                >
                  Get started
                </a>
              </div> */}
            </div>
          </div>
          {/* <div className="mt-8 border-t border-gray-200 pt-6">
            <blockquote>
              <div>
                <p className="text-base text-gray-500">
                  &ldquo;Cras velit quis eros eget rhoncus lacus ultrices sed diam. Sit orci risus aenean
                  curabitur donec aliquet. Mi venenatis in euismod ut.&rdquo;
                </p>
              </div>
              <footer className="mt-3">
                <div className="flex items-center space-x-3">
                  <div className="flex-shrink-0">
                    <img
                      className="h-6 w-6 rounded-full"
                      src={mamadrive}
                      alt=""
                    />
                  </div>
                  <div className="text-base font-medium text-gray-700">
                    Marcia Hill, Digital Marketing Manager
                  </div>
                </div>
              </footer>
            </blockquote>
          </div> */}
        </div>
        <div className="mt-12 sm:mt-16 lg:mt-0">
          <div className="pl-4 -mr-8 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
            <img
              className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none bg-black pl-10 py-4"
            src={mamadrive}
              alt="Inbox user interface"
            />
          </div>
        </div>
      </div>
    </div>
    <div className="mt-24">
      <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
        <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
          <div>
            
            <div className="mt-6">
              <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
              2.	GREEN MAMA – WASTE AND ENVIRONMENTAL MANAGEMENT PLATFORM
              </h2>
              <p className="mt-4 text-lg text-gray-500">
             

Green Mama waste management solution offers a real-time digital mapping of waste disposers, hawkers, tracking of collection routes and management of personnel and resources for efficient waste management. To manage waste data in an integrated way so that the complexity of various systems could be reduced to solve all its interrelated issues. Green Mama is a Pan-African women driven waste management and hawking solution.

              </p>
              {/* <div className="mt-6">
                <a
                  href="/"
                  className="inline-flex bg-gradient-to-r from-green-600 to-green-600 bg-origin-border px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-green-700 hover:to-green-700"
                >
                  Get started
                </a>
              </div> */}
            </div>
          </div>
        </div>
        <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
          <div className="pr-4 -ml-8 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
          <img
              className="w-96 rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full"
            src={greenmama}
              alt="Inbox user interface"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Programmes