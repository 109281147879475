import React from 'react'
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import {
    AnnotationIcon,
    ChatAlt2Icon,
    ChatAltIcon,
    DocumentReportIcon,
    HeartIcon,
    InboxIcon,
    MenuIcon,
    PencilAltIcon,
    QuestionMarkCircleIcon,
    ReplyIcon,
    SparklesIcon,
    TrashIcon,
    UsersIcon,
    XIcon,ChevronDownIcon,
  } from '@heroicons/react/solid'
  
  import icon from '../assets/greenmama.png'
import Contact from './../Contact/Contact';

  const solutions = [
    {
      name: 'Inbox',
      description: 'Get a better understanding of where your traffic is coming from.',
      href: '/',
      icon: InboxIcon,
    },
    {
      name: 'Messaging',
      description: 'Speak directly to your customers in a more meaningful way.',
      href: '/',
      icon: AnnotationIcon,
    },
    { name: 'Live Chat', description: "Your customers' data will be safe and secure.", href: '/', icon: ChatAlt2Icon },
    {
      name: 'Knowledge Base',
      description: "Connect with third-party tools that you're already using.",
      href: '/',
      icon: QuestionMarkCircleIcon,
    },
  ]



  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

const Header = () => {
  return (
    <header>
    <Popover className="relative bg-white">
      <div className="flex justify-between items-center max-w-7xl mx-auto px-4 py-6 sm:px-6 md:justify-start md:space-x-10 lg:px-8">
        <div className="flex justify-center w-full h-full sm:justify-start lg:w-0 lg:flex-1">
          <a href="/">
        
            <img
              className="h-8 sm:w-auto sm:h-10 w-full "
              src={icon}
              alt=""
            />
          </a>
        </div>
      
        <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
        
          <a
            href="/"
            className="ml-8 whitespace-nowrap inline-flex items-center justify-center bg-gradient-to-r from-green-600 to-green-600 bg-origin-border px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white hover:from-green-700 hover:to-green-700"
          >
          Contact Us
          </a>
        </div>
      </div>

    
    </Popover>
  </header>
  )
}

export default Header