import React from 'react'
import logo from '../assets/homeajax.jpg'


const Herosection = () => {
  return (
   <>
     {/* Hero section */}
     <div className="relative">
          <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100" />
          <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
            <div className="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
              <div className="absolute inset-0">
                <img
                  className="h-full w-full object-cover"
                  src="https://images.pexels.com/photos/1181360/pexels-photo-1181360.jpeg?cs=srgb&dl=pexels-christina-morillo-1181360.jpg&fm=jpg&_gl=1*ul8s8n*_ga*MTk2OTMxMjc0MS4xNjYzMjQzNTE5*_ga_8JE65Q40S6*MTY2NjI1Mjc0My42LjEuMTY2NjI1NTIwMy4wLjAuMA.."
                  alt="People working on laptops"
                />
                <div className="absolute inset-0 bg-gradient-to-r from-green-500 to-green-400 mix-blend-multiply" />
              </div>
              <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
                <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
                  <span className="block text-white capitalize">To empower women in Africa</span>
                
                </h1>
                <p className="mt-6 max-w-lg mx-auto text-center text-xl text-green-100 sm:max-w-3xl">
                Through empowerment and hard work, strong women in Africa have been successful in the social, economic and political circles across the continent.
                </p>
                {/* <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
                  <div className="space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5">
                    <a
                      href="/"
                      className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-green-700 bg-white hover:bg-green-50 sm:px-8"
                    >
                      Get started
                    </a>
                    <a
                      href="/"
                      className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-green-500 bg-opacity-60 hover:bg-opacity-70 sm:px-8"
                    >
                      Live demo
                    </a>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
   </>
  )
}

export default Herosection